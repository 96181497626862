<template>
  <div>
    <r-combine-dataset
      v-show="selectedItem==='r_combine_dataset'"
      @reset="reset"
      @is-combined="isCombined"
      :minNumericVariable="0"
      :minSample="5"
      :analysisObj="getParentCombined()"
    />
    <r-mfa-step
      ref="mfastep"
      v-show="selectedItem==='r_mfa'"
      :dataset.sync="combinedObjectName"
      :analysisObj="getAnalysisForStep('r_mfa')"
      @is-computed="isComputed"
    >
    </r-mfa-step>
    <r-plotind
      v-show="selectedItem==='r_plotind'"
      ref="plotind"
      :datasetName="MFAobjectName"
      :availablePartialIndGroup="availablePartialIndGroup"
      :availableIndividuals="availableIndividuals"
      :nbCP="nAxes" />
    <r-plotvar
      v-show="selectedItem==='r_plotvar'"
      ref="plotvar"
      :datasetName="MFAobjectName"
      :nbCP="nAxes" />
    <r-plotgroup
      v-show="selectedItem==='r_plotgroup'"
      ref="plotgroup"
      :datasetName="MFAobjectName"
      :nbCP="nAxes" />
    <r-extract-obj
      v-if="selectedItem==='r_extract_obj'"
      :extractSuffix="combinedSuffix"
      :datasetName="MFAobjectName"
      datasetPrefix="MFA"
      :nbCP="nAxes" />
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'
export default {
  name: 'RMFA',
  components: {
    'r-plotind': () => import('@/components/ranalyses/RPlotind.vue'),
    'r-plotvar': () => import('@/components/ranalyses/RPlotvar.vue'),
    'r-plotgroup': () => import('@/components/ranalyses/RPlotgroup.vue'),
    'r-mfa-step': () => import('@/components/ranalyses/RMFAStep.vue'),
    'r-combine-dataset': () => import('@/components/ranalyses/RCombineDataset.vue'),
    'r-extract-obj': () => import('@/components/ranalyses/RExtractObj.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Preprocessing',
          name: 'r_combine_dataset',
          disabled: false
        },
        {
          title: 'Run MFA',
          name: 'r_mfa',
          disabled: true
        },
        {
          title: 'Explore individuals',
          name: 'r_plotind',
          disabled: true
        },
        {
          title: 'Explore variables',
          name: 'r_plotvar',
          disabled: true
        },
        {
          title: 'Explore groups',
          name: 'r_plotgroup',
          disabled: true
        },
        {
          title: 'Extract new data',
          name: 'r_extract_obj',
          disabled: true
        }
      ],
      combinedObjectName: null,
      combinedSuffix: '',
      MFAobjectName: null,
      availableIndividuals: [],
      availablePartialIndGroup: []
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    nAxes: function () {
      let nAxes = 0
      let mfaAnalysis = this.analyses.find(obj => obj.object_name === this.MFAobjectName)
      if (mfaAnalysis !== undefined) {
        nAxes = Number.parseInt(mfaAnalysis.meta.func_args.ncp)
      }
      return nAxes
    }
  },
  methods: {
    reset: function () {
      this.combinedObjectName = null
      this.combinedSuffix = null
      this.menuItems[1].disabled = true
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.menuItems[5].disabled = true
      this.MFAobjectName = null
      this.availableIndividuals = []
      this.availablePartialIndGroup = []
    },
    isCombined: function (combinedObj) {
      this.combinedObjectName = combinedObj.combinedObjectName
      this.combinedSuffix = combinedObj.combinedDatasets.map(obj => obj.user_name).join('_')
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.menuItems[5].disabled = true
      this.$refs.mfastep.reset()
    },
    isComputed: function (mfaObj) {
      this.MFAobjectName = mfaObj.MFAobjectName
      this.availableIndividuals = mfaObj.availableIndividuals
      this.availablePartialIndGroup = []
      let analysis = this.analyses.find(obj => obj.object_name === this.MFAobjectName)
      if (analysis !== undefined) {
        this.availablePartialIndGroup = analysis.groups
      }
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
      this.menuItems[4].disabled = false
      this.menuItems[5].disabled = false
      this.menuStore.selectItem(this.menuItems[1])
    }

  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
  }
}
</script>
